
.login-root {
    min-height: 100vh; /* Take up full screen */
    background: var(--MC_DARK);
    color: white;
}

.title {
    font-size: 30px;
    font-weight: bold;
}
.subtitle {
    font-size: 18px;
}
.power {
    font-size: 14px;
}

.main-logo {
    margin-top: 5vh;
    /*width: 200px;*/
    max-height: 20vh;
    pointer-events: none;
}
.login-header {
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*font-size: calc(10px + 2vmin);*/
    color: white;
}


.card-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    flex-basis: auto;
}
.card-actions {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.login-input-div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
.login-input-title {
    justify-self: flex-start;
    font-size: large;
}

.login-input-field {
    padding: 8px;
}
.login-btn-text {
    padding-left: 24px;
    padding-right: 24px;
}

.login-spinner {
    padding: 8px;
}

.link {
    color: white; /* Not working */
    text-decoration-color: white;
}

.version {
    padding: 3px;
    font-size: small;
    color: grey;
}

/*
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
*/