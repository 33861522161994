
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 250%;
}
h2 {
  font-size: 175%;
}
h3 {
  font-size: 150%;
}
h4 {
  font-size: 125%;
}
p {
  font-size: 100%;
}

:root {
  /*
  Core color:
  #0D2444
  rgb(13,36,68)
  */
  --MC_MAIN: rgba(13,36,68, 0.87);
  --MC_DARK: rgba(13,36,68, 0.99);
  --MC_ACCENT: #A5A6A8;
  --SILVER: #C0C0C0;
}

.note {color: grey;}
.block {display: block;}
.error-note {color: lightcoral;}

.admin {color: gold;}
.good {color: green;}
.bad {color: red;}

.gold {color: gold;}
.green {color: green;}
.green-txt {color: green;}
.orange {color: orange;}
.red {color: red;}
.yellow {color: yellow;}
.gray {color: grey;}
.grey {color: grey;}


/* Override Global TabPanel size */
.MuiTabPanel-root {
  width: 100%;
}
.no-pointer-events {
  pointer-events: none;
}
