
.admincore-root {


}

.admin-core-header-side {
    width: 50px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-self: center;
}



/* These classes are used as a child selected in the SX prop */
.menu-btn-icon {}
.menu-btn-text {}


.menu-logo {
    margin-top: 24px;
    margin-bottom: 12px;
    align-self: center;
    pointer-events: none;
    /*height: 100px;*/
    width: 120px;
}

.main-outlet {
}
.menu-btn:hover {
    background: var(--MC_DARK);
}

.log-out-text {
    color: red;
}