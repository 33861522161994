
.cs-root {
    width: 90%;
    margin: auto;
}
.subtitle {
    font-size: large;
    font-weight: bold;
}
.desc-span {
    font-size: small;
    color: grey;
    margin-bottom: 8px;
}

.cmv-row {
    margin-bottom: 6px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.cmv-div {
    margin-left: 12px;
    margin-right: 12px;
}
